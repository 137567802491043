import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { DefaultProps } from '../../components/selectDropdown/ISelectDropdown'
import UserContext from '../user/UserContext'

export interface FilterPayloadInterface {
  searchString: string
  accountnumber: string
  repcode: string
  notedate: string
  duedate: string
  startdate: string
  typeid: number
  subtypeid: number
  offset: number
}

export const initialFilterValue: DefaultProps = {
  value: '',
  label: 'All',
}

export interface TableFilterInterface {
  searchTerm: string
  accountnumber: string
  repcodeValue: DefaultProps
  typeValue: DefaultProps
  subTypeValue: DefaultProps
}

export interface FilterContextInterface {
  loadingEdit: boolean
  filterPayload: FilterPayloadInterface
  setFilterPayload: Dispatch<SetStateAction<FilterPayloadInterface>>
  setInteractionsPayload: (tranType: string) => any
  setOpportunitiesPayload: (stage: number, selectedTab: string) => any
  tableFilter: TableFilterInterface
  setTableFilter: Dispatch<SetStateAction<TableFilterInterface>>
  scrollPosition: number
  setScrollPosition: Dispatch<SetStateAction<number>>
  resetTableFilter: () => void
}

const FilterContext = createContext<FilterContextInterface>(
  {} as FilterContextInterface
)

interface ContextProps {
  children?: ReactNode
}

export const FilterContextProvider = ({ children }: ContextProps) => {
  const [loadingEdit] = useState(false)
  const [filterPayload, setFilterPayload] = useState<FilterPayloadInterface>({
    offset: 0,
  } as FilterPayloadInterface)
  const [tableFilter, setTableFilter] = useState<TableFilterInterface>({
    searchTerm: '',
    accountnumber: '',
    repcodeValue: initialFilterValue,
    typeValue: initialFilterValue,
    subTypeValue: initialFilterValue,
  } as TableFilterInterface)
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  // const [offset, setOffset] = useState<number>(0)
  const { user } = useContext(UserContext)

  function addOneDay(isoString: string) {
    // Create a new Date object from the ISO string
    const date = new Date(isoString)

    // Add one day to the date
    date.setDate(date.getDate() + 1)

    // Return the new date as an ISO string
    return date.toISOString().split('T')[0] + 'T00:00:00Z'
  }

  const setInteractionsPayload = useCallback(
    (tranType: string) => {
      const payload = {}
      const array = { ...filterPayload }

      if (tranType === 'note' || tranType === 'event') {
        const temp = { ownerid: { _iregex: user.username } }
        Object.assign(payload, temp)
      }
      if (tranType === 'noteBook') {
        const temp = {
          _or: [
            { accountad: { _eq: user.username } },
            { partyad: { _eq: user.username } },
          ],
          trantype: { _eq: 1 },
        }
        Object.assign(payload, temp)
      }
      if (tranType === 'task') {
        const temp = {
          _or: [
            { assignedto: { _iregex: user.username } },
            { ownerid: { _iregex: user.username } },
          ],
        }
        Object.assign(payload, temp)
      }

      for (const [key, value] of Object.entries(array)) {
        if (
          key !== 'notedate' &&
          key !== 'duedate' &&
          key !== 'startdate' &&
          key !== 'accountnumber' &&
          key !== 'searchString' &&
          key !== 'offset' &&
          value != ''
        ) {
          const temp = { [key.toLowerCase()]: { _eq: value } }
          Object.assign(payload, temp)
        }
      }

      if (filterPayload.notedate) {
        const temp = {
          _and: [
            { notedate: { _gte: filterPayload.notedate } },
            { notedate: { _lte: addOneDay(filterPayload.notedate) } },
          ],
        }
        Object.assign(payload, temp)
      }

      if (filterPayload.duedate) {
        const temp = {
          _and: [
            { duedate: { _gte: filterPayload.duedate } },
            { duedate: { _lte: addOneDay(filterPayload.duedate) } },
          ],
        }
        Object.assign(payload, temp)
      }
      if (filterPayload.startdate) {
        const temp = {
          _and: [
            { startdate: { _gte: filterPayload.startdate } },
            { startdate: { _lte: addOneDay(filterPayload.startdate) } },
          ],
        }
        Object.assign(payload, temp)
      }

      if (filterPayload.searchString) {
        const temp: any = {
          _or: [
            { subject: { _iregex: filterPayload.searchString } },
            { partyname: { _iregex: filterPayload.searchString } },
            { accountname: { _iregex: filterPayload.searchString } },
            { accountnumber: { _iregex: filterPayload.searchString } },
            { typedescription: { _iregex: filterPayload.searchString } },
            { subtypedescription: { _iregex: filterPayload.searchString } },
          ],
        }
        if (tranType !== 'noteBook') {
          temp._or.push({ repcode: { _iregex: filterPayload.searchString } })
        }
        if (tranType == 'event') {
          temp._or.push({ location: { _iregex: filterPayload.searchString } })
        }

        Object.assign(payload, temp)
      }
      if (filterPayload.accountnumber) {
        const temp: any = {
          _and: [{ accountnumber: { _iregex: filterPayload.accountnumber } }],
        }
        // if (tranType !== 'noteBook') {
        //   temp._and.push({ repcode: { _iregex: filterPayload.accountnumber } })
        // }
        // if (tranType == 'event') {
        //   temp._and.push({ location: { _iregex: filterPayload.accountnumber } })
        // }

        Object.assign(payload, temp)
      }
      return payload
    },
    [filterPayload, user.username]
  )

  const setOpportunitiesPayload = useCallback(
    (stage: number, selectedTab: string) => {
      const payload = {}
      const array = { ...filterPayload, stage: stage }
      const temp =
        selectedTab == 'Created By'
          ? { createdby: { _iregex: user.username } }
          : { ADUserName: { _iregex: user.username } }
      Object.assign(payload, temp)

      for (const [key, value] of Object.entries(array)) {
        if (key === 'repcode' || key === 'stage') {
          const temp = { [key.toLowerCase()]: { _eq: value } }
          Object.assign(payload, temp)
        }
      }

      if (filterPayload.searchString) {
        const temp: any = {
          _or: [
            { name: { _iregex: filterPayload.searchString } },
            { contactname: { _iregex: filterPayload.searchString } },
            { assignedtoname: { _iregex: filterPayload.searchString } },
            { nextsteps: { _iregex: filterPayload.searchString } },
            { source: { _iregex: filterPayload.searchString } },
            { repcode: { _iregex: filterPayload.searchString } },
          ],
        }

        Object.assign(payload, temp)
      }
      return payload
    },
    [filterPayload, user.username]
  )

  const resetTableFilter = () => {
    setFilterPayload({ offset: 0 } as FilterPayloadInterface)
    setTableFilter({
      searchTerm: '',
      accountnumber: '',
      repcodeValue: initialFilterValue,
      typeValue: initialFilterValue,
      subTypeValue: initialFilterValue,
    } as TableFilterInterface)
  }

  const value = useMemo(
    () => ({
      loadingEdit,
      filterPayload,
      setFilterPayload,
      setInteractionsPayload,
      setOpportunitiesPayload,
      tableFilter,
      setTableFilter,
      scrollPosition,
      setScrollPosition,
      resetTableFilter,
    }),
    [
      loadingEdit,
      filterPayload,
      setInteractionsPayload,
      setOpportunitiesPayload,
      tableFilter,
      scrollPosition,
    ]
  )

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}

export default FilterContext
