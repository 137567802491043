import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
} from '@azure/msal-browser'
import {
  MsalAuthenticationTemplate,
  useMsal
} from '@azure/msal-react'
import { useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './AppRoutes'
import AxiosProtectedInterceptor from './composites/interceptor/AxiosProtectedInterceptor'
import AuthContextProvider from './services/AuthContext'
import InteropContextProvider from './services/InteropContextProvider'
import { SearchContextProvider } from './services/search/SearchContext'
import { ToastContextProvider } from './services/toast/ToastContext'
import useIsInteropEnv from './services/useIsInteropEnv'
import UserContext from './services/user/UserContext'
import { loginRequest } from './settings/authConfig'
import store from './store/store'

// type AppProps = {
//   pca: IPublicClientApplication
// }

function App() {
  const { setUser } = useContext(UserContext)
  const { isInteropEnv } = useIsInteropEnv()
  const authRequest = {
    ...loginRequest,
  }
  const { instance } = useMsal()

  const getAdIdFromEmail = (email?: string) => {
    if (email) {
      return email.split('@wedbush.com')[0]
    } else {
      return ''
    }
  }

  instance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      instance.setActiveAccount(account)
      setUser({
        ...account,
        name: account?.idTokenClaims?.name ? account?.idTokenClaims?.name : '',
        username: getAdIdFromEmail(account?.idTokenClaims?.Username as string),
      } as AccountInfo)
    }
  })

  const onIdle = () => {
    sessionStorage.clear()
    instance.logout()
  }

  useIdleTimer({
    onIdle,
    timeout: 1200000,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  })

  return (
    <>
      {isInteropEnv ? (
        <InteropContextProvider>
          <Provider store={store}>
            <AuthContextProvider>
              <ToastContextProvider>
                <AxiosProtectedInterceptor />
                <BrowserRouter>
                  <SearchContextProvider>
                    <AppRoutes />
                  </SearchContextProvider>
                </BrowserRouter>
              </ToastContextProvider>
            </AuthContextProvider>
          </Provider>
        </InteropContextProvider>
      ) : (
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
        >
          <Provider store={store}>
            <AuthContextProvider>
              <ToastContextProvider>
                <AxiosProtectedInterceptor />
                <BrowserRouter>
                  <SearchContextProvider>
                    <AppRoutes />
                  </SearchContextProvider>
                </BrowserRouter>
              </ToastContextProvider>
            </AuthContextProvider>
          </Provider>
        </MsalAuthenticationTemplate>
      )}
    </>
  )
}

export default App
