import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser'
import { useAccount, useMsal } from '@azure/msal-react'
import { IOConnectContext } from '@interopio/react-hooks'
import React, { createContext, useContext, useEffect, useState } from 'react'
import appConfig from '../settings/appConfig'
import { protectedResources } from '../settings/authConfig'
import useIsInteropEnv from './useIsInteropEnv'
import UserContext from './user/UserContext'

export interface AuthCtxProviderInterface {
  accessToken: string
  userAccessDetails: UserAccessInterface
  client: any
  setUserAccessDetails: (userAccessDetails: UserAccessInterface) => void
}

export interface UserAccessInterface {
  repAccess?: string[]
  accessLevels?: string
  role: string
}

export const AuthContext = createContext<AuthCtxProviderInterface>({
  accessToken: '',
  userAccessDetails: {
    repAccess: undefined,
    accessLevels: undefined,
    role: '',
  },
  setUserAccessDetails: () => null,
  client: {},
})

interface AuthCtxProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

function AuthContextProvider({ children }: AuthCtxProviderProps) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [accessToken, setAccessToken] = useState<string>('')
  const [client, setClient] = useState<any>()
  const { setUser } = useContext(UserContext)
  const io = useContext(IOConnectContext)
  const { isInteropEnv } = useIsInteropEnv()

  const [userAccessDetails, setUserAccessDetails] = useState<{
    accessLevels?: string
    repAccess?: string[]
    role: string
  }>({
    //accessLevels: [],
    //repAccess: [],
    role: '',
  })

  const authMiddleware = (authToken: any) =>
    new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      if (authToken) {
        operation.setContext({
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
      }

      return forward(operation)
    })

  const setValues = (token: string) => {
    const httpLink = new HttpLink({
      uri: appConfig.hasuraEndpoint,
    })

    const authLink = setContext((_, { headers }) => {
      const headerValue = {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
      return headerValue
    })

    const client = new ApolloClient({
      cache: new InMemoryCache({
        addTypename: false,
      }),
      link: ApolloLink.from([authMiddleware(token), authLink.concat(httpLink)]),
    })

    setClient(client)
  }
  const getAdIdFromEmail = (email?: string) => {
    if (email) {
      return email.split('@wedbush.com')[0]
    } else {
      return ''
    }
  }

  // Use effect to fetch SSO token on component mount
  useEffect(() => {
    const fetchSSOToken = async () => {
      if (isInteropEnv) {
        await io?.contexts?.subscribe('AIW_SSO_AUTH_RESPONSE', (data: any) => {
          setAccessToken(data?.accessToken)
          setValues(data?.accessToken)
          setUser({
            ...data,
            name: data?.idTokenClaims?.name ? data?.idTokenClaims?.name : '',
            username: getAdIdFromEmail(data?.idTokenClaims?.Username as string),
          } as AccountInfo)
        })
      } else if (account && inProgress === 'none') {
        await instance
          .acquireTokenSilent({
            scopes: protectedResources.api.scopes,
            account: account,
          })
          .then(async (response) => {
            setAccessToken(response?.accessToken)
            setValues(response?.accessToken)
          })
      }
    }

    fetchSSOToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [io?.contexts, account, inProgress, instance])

  // useEffect(() => {
  //   b2cAuth()
  // }, [account, inProgress, instance])

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        userAccessDetails: userAccessDetails,
        client: client,
        setUserAccessDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
